import { render, staticRenderFns } from "./parentFatList.vue?vue&type=template&id=3e8ea714&scoped=true&%3Ais=view&transition=fade&transition-mode=out-in&"
import script from "./parentFatList.vue?vue&type=script&lang=js&"
export * from "./parentFatList.vue?vue&type=script&lang=js&"
import style0 from "./parentFatList.vue?vue&type=style&index=0&id=3e8ea714&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e8ea714",
  null
  
)

export default component.exports