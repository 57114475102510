/**
 * Created by zm on 17/6/1.
 *
 */
import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 获取故障所有分类级别
 * @param id String 故障ID
 * @returns {Promise.<*>}
 */
export async function getAllfaultLevels(
  key,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("orderCategory", { key, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 根据一级故障Id获取所有子级故障
 * @returns {Promise.<*>}
 */
export async function getAllFaultCategoryByParentId(
  parentId,
  key,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("orderCategory/byParentCategory/" + parentId, {
      key,
      ...pager,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有root为true的faultCategory
 * @returns {Promise.<*>}
 */
export async function getAllFaultCategoryByroot(
  key,
  pager = { size: 999, page: 1 }
) {
  try {
    return await API.get("orderCategory/root", { key, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建一个faultCategory
 * @returns {Promise.<*>}
 */
export async function createFaultCategory(faultCategory) {
  try {
    return await API.post("orderCategory", faultCategory);
  } catch (error) {
    return error;
  }
}

/**
 * 修改一个故障
 * @param team Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function updateFaultCategory(faultCategory) {
  try {
    return await API.post("orderCategory/update", faultCategory);
  } catch (error) {
    return error;
  }
}

/**
 * 删除故障
 * @returns {Promise.<*>}
 */
export async function removefaultCategory(ids) {
  try {
    return await API.del("orderCategory/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取故障的信息
 * @param id String 故障ID
 * @returns {Promise.<*>}
 */
export async function getFaultCategoryById(id) {
  try {
    return await API.get("orderCategory/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 获取故障所有分类类型
 * @param pager Object 分页对象
 * @returns {Promise.<*>}
 */
export async function getAllFaultCategorys(pager) {
  try {
    return await API.get("orderCategory", pager);
  } catch (error) {
    return error;
  }
}
